import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
   
  },
  
  { 
    path: 'home',
    children: [
      {
        path : '',
        loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
        canActivate: [AuthGuard]
      },

      {
        path : ':eventId',
        loadChildren: () => import('./pages/scan/scan.module').then( m => m.ScanPageModule),
        canActivate: [AuthGuard]
      }
      ]
  },

  {
    path: 'scan',
    loadChildren: () => import('./pages/scan/scan.module').then( m => m.ScanPageModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
